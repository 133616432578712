import React, { useEffect, useState } from "react";
import { Root } from "./style";

import { formatNumber, useSiteData } from "utils";

import PageHeader from "components/Common/PageHeader";
import RawHTML from "components/Common/RawHTML";
import SiteImage from "components/Common/SiteImage";
import InfoBox from "components/Common/InfoBox";

import vars from "variables";

const HODLXExchanges = ({ pageContext: { hodlxExchanges } }) => {
  const { hodlxExchanges: page } = useSiteData().pages;
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    fetch(`${vars.apiHost}/v2/-/hodl-markets/hodlx`)
      .then((response) => response.json())
      .then((data) => {
        setMarkets(
          data.reduce((object, [exchange, price, change24h, change7d]) => {
            object[exchange] = {
              price,
              change24h,
              change7d,
            };
            return object;
          }, {})
        );
      });
  }, []);

  const types = {
    cex: page.cexTooltip,
    dex: page.dexTooltip,
  };

  const market = ({ identifier, usePCSData }) => {
    return (
      markets[usePCSData ? "pancakeswap" : identifier] || {
        price: 0,
        change24h: 0,
        change7d: 0,
      }
    );
  };

  const price = (exchange) => market(exchange).price.toString().substr(0, 15);
  const change24h = (exchange) => formatNumber(market(exchange).change24h, 2);
  const change7d = (exchange) => formatNumber(market(exchange).change7d, 2);

  const class24h = (exchange) =>
    market(exchange).change24h < 0 ? "negative" : "positive";
  const class7d = (exchange) =>
    market(exchange).change7d < 0 ? "negative" : "positive";

  return (
    <Root className="body-width">
      <PageHeader page="hodlxExchanges" />
      <ul>
        {hodlxExchanges.map((exchange) => (
          <li key={exchange.id} className="exchange">
            <div className="price">
              <SiteImage className="logo" image={exchange.logo} />
              <div className="stats">
                <div>
                  <span className="hodlx">HODLX</span>
                </div>
                <div className="right">
                  <span>$ {price(exchange)}</span>
                </div>
                <div>
                  <span>24H %</span>
                  <span>
                    <small className={class24h(exchange)}>
                      {change24h(exchange)}%
                    </small>
                  </span>
                </div>
                <div className="right">
                  <span>7D %</span>
                  <span>
                    <small className={class7d(exchange)}>
                      {change7d(exchange)}%
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="info">
              <InfoBox info={types[exchange.type]}>{exchange.type}</InfoBox>
              <h3>{exchange.name}</h3>
              <RawHTML html={exchange.information} />
              <div className="links">
                <a href={exchange.buyURL} target="_blank" rel="noreferrer">
                  Buy now
                </a>
                <a href={exchange.chartURL} target="_blank" rel="noreferrer">
                  View chart
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Root>
  );
};

export default HODLXExchanges;
